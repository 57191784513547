<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id"
    entity-name="计划"
    add-btn="添加计划"
    hide-show-detail
    show-row-num
    :rules="rules"
    :columns="columns"
    :search-key-type="searchKeyType"
    :before-edit="beforeEdit"
    :before-update="beforeUpdate"
    :action="action"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item>
        <a-range-picker
          v-model="form.date"
          :placeholder="['开始日期', '结束日期']"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          allowClear
          @change="updateList"
          class="range-picker"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-select
          style="width: 180px"
          placeholder="选择进度"
          @change="updateList"
          :options="scheduleOptions"
          v-model="form.schedule"
          allow-clear
        >
        </a-select>
      </a-form-model-item>
    </template>

    <template v-slot:edit="{ form }">
      <a-form-model-item label="开始日期" prop="start_time">
        <a-date-picker
          v-model="form.start_time"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder="选择日期"
          allow-clear
          style="width: 100%;"
        />
      </a-form-model-item>
      <a-form-model-item label="结束日期" prop="end_time">
        <a-date-picker
          v-model="form.end_time"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder="选择日期"
          allow-clear
          style="width: 100%;"
        />
      </a-form-model-item>
      <a-form-model-item label="工作内容" prop="content">
        <a-textarea v-model="form.content" :rows="4" placeholder="请输入工作内容" allow-clear></a-textarea>
      </a-form-model-item>
      <a-form-model-item label="志工报名人数" prop="enroll_num">
        <a-input-number
          v-model="form.enroll_num"
          placeholder="请输入报名人数"
          :min="1"
          :step="1"
          style="width: 100%"
          allow-clear
        ></a-input-number>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
import { scheduleOptions } from "../../common/constant/program/type";
import { stringConvertTimestamp, timestampConvertString, getKeyTitle, clone, getCurrentDateTimestamp } from "../../common/js/tool";



export default {
  name: "Plan",
  data() {
    return {
      url: "/admin/volunteer-work-plan",
      searchKeyType: {
        date: function(form, key) {
          let value = form[key];
          let res = "";
          if (value?.length > 0) {
            res = `&filter[start_time][gte]=${stringConvertTimestamp(
              value[0]
            )}&filter[end_time][lte]=${stringConvertTimestamp(value[1])}`;
          }
          return res;
        },
        schedule: function(form, key) {
          let value = form[key];
          let res = "";
          let now = getCurrentDateTimestamp();
          switch (value) {
            case 1:
              // 进行中
              res += `&filter[start_time][lte]=${now}&filter[end_time][gte]=${now}`;
              break;
            case 2:
              // 未开始
              res += `&filter[start_time][gt]=${now}`;
              break;
            case 3:
              // 已结束
              res += `&filter[end_time][lt]=${now}`;
              break;
          }
          return res;
        },
      },
      columns: [
        {
          title: "日期",
          customRender: (text) => {
            const { start_time, end_time } = text;
            return `${timestampConvertString(start_time, "yyyy-MM-dd")} 至 ${timestampConvertString(
              end_time,
              "yyyy-MM-dd"
            )}`;
          },
        },
        { title: "工作内容", dataIndex: "content" },
        {
          title: "项目进度",
          customRender: (text) => {
            let { schedule, start_time, end_time } = text;
            if (!schedule) {
              let now = getCurrentDateTimestamp();
              if (now < start_time) {
                schedule = 2;
              } else if (now > end_time) {
                schedule = 3;
              } else {
                schedule = 1;
              }
            }
            return getKeyTitle(scheduleOptions, schedule, "value", "label") || "-";
          },
        },
        { title: "志工报名人数", dataIndex: "enroll_num" },
      ],
      rules: {
        start_time: [{ required: true, message: "请选择开始日期", trigger: "change" }],
        end_time: [{ required: true, message: "请选择结束日期", trigger: "change" }],
        content: [{ required: true, message: "请输入工作内容", trigger: "blur" }],
        enroll_num: [{ required: true, message: "请输入志工报名人数", trigger: "blur" }],
      },
      scheduleOptions,
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
  },
  created() {},
  methods: {
    getList() {
      this.$refs.dataList.getList();
    },
    updateList() {
      this.$refs.dataList.updateList();
    },
    beforeEdit(form, fn) {
      let detail = clone(form);
      if (detail.start_time) {
        detail.start_time = timestampConvertString(detail.start_time, "yyyy-MM-dd");
      }
      if (detail.end_time) {
        detail.end_time = timestampConvertString(detail.end_time, "yyyy-MM-dd");
      }
      fn(detail);
    },
    beforeUpdate(form, fn) {
      let res = clone(form);
      if (res.start_time) {
        res.start_time = stringConvertTimestamp(res.start_time);
      }
      if (res.end_time) {
        res.end_time = stringConvertTimestamp(res.end_time);
      }
      fn(res);
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  color: #909090;
  font-size: 12px;
  margin-top: 8px;
}
</style>
